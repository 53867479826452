<div class="app" [class]="currentTheme$()">
  <div class="app__wrapper" [@routerFade]="prepareRoute(outlet)" [class.anim--in]="!loading$()">
    <app-topbar
      (toggle)="toggleMenu()"
      (close)="closeMenu()"
      (open)="openMenu()"
      [menuActive]="menuOpen$()"
      [menuItems]="menuItems$()"
      [footerItems]="footerItems$()"
      [socialItems]="socialItems"
      [topbar]="topbar$()"
      [colorType]="currentTheme$()"
      *ngIf="dictionaryLoaded$()"
    ></app-topbar>
    <router-outlet #outlet class="app__router"></router-outlet>
  </div>

  <app-footer [socialItems]="socialItems" [menuItems]="footerItems$()" *ngIf="dictionaryLoaded$()" [theme]="footerTheme$()"></app-footer>

  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-title' | fromDictionary"
    *ngIf="dictionaryLoaded$()"
    [text]="'cookie-text' | fromDictionary"
    [buttonAccept]="'cookie-accept' | fromDictionary"
    [buttonReject]="'cookie-reject' | fromDictionary"
    [buttonAcceptAll]="'cookie-accept-all' | fromDictionary"
  ></fstr-cookie-notice>

  <app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
</div>
