import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { DictionaryConfig, DictionaryNgrxModule, DictionaryGuard, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';

import { reducers, CustomSerializer, appEffects, metaReducers } from './store';

// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotFoundComponent } from './404.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentModule } from './content/content.module';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { GridOverlayComponent } from './standalone/grid-overlay/grid-overlay.component';

const dictConfig: Partial<DictionaryConfig> = {
  mapping: [
    {
      domainPart: '.nl/',
      language: 'nl',
    },
  ],
};

const cookieConfig: CookieConfig = {
  analyticsCode: 'UA-20801084-1',
  strippedContentPlaceholder: 'This content is not shown because you have not accepted cookies',
  buttonSettings: 'Settings',
  buttonAcceptAll: 'Accept all',
  buttonClose: 'Close',
  strippedContentButton: 'Change cookie settings',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  typeLabels: {
    functional: 'Functional cookies',
    analytical: 'Analytical cookies',
    marketing: 'Marketing cookies',
  },
  showSettings: true,
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    IconModule.forRoot({ spritesheetUrl: 'assets/gfx/svg-sprite.svg' }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    DictionaryNgrxModule.forRoot(dictConfig),
    CookieNgrxModule.forRoot(cookieConfig),
    ContentModule,
    GridOverlayComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
