import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
  Optional,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  inject,
  computed,
  effect,
} from '@angular/core';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, ActivatedRoute, ActivationEnd, ChildActivationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { DictionaryState } from '@teamfoster/sdk/dictionary-ngrx';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { ContentPage } from './content/models';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';

import * as themeActions from './store/actions/theme.action';
import * as themeSelectors from './store/selectors/theme.selector';

import * as selectors from './content/store/selectors/content-pages.selector';
import { tap, take, withLatestFrom, filter } from 'rxjs/operators';
import { routeTransitionAnimations } from './animations';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl, getRouterState } from './store';
// import { getSelectedContentPageSubmenu } from './content/store/selectors/content-pages.selector';

import { TopbarConfig } from './store/interfaces/topbar-config.model';
import {
  CookieConfig,
  CookieConfigService,
  CookiePreferences,
  getCookiePreferences,
  ShowCookieSettings,
} from '@teamfoster/sdk/cookie-ngrx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routeTransitionAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);
  private cookieConfig = inject(CookieConfigService);

  private preventScrollKeys = ['discipline', 'workfield', 'typology', 'status', 'country', 'q', 'project'];

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  cookiePrefs$ = this.store.selectSignal(getCookiePreferences);
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  menuItems$ = this.store.selectSignal(selectors.getMenuPages);
  footerItems$ = this.store.selectSignal(selectors.getFooterPages);
  topbar$ = this.store.selectSignal(navSelectors.getTopbarConfig);
  currentTheme$ = this.store.selectSignal(themeSelectors.getCurrentTheme);
  footerTheme$ = this.store.selectSignal(themeSelectors.getFooterTheme);
  private routerEvents$ = toSignal(this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd].some(b => e instanceof b))));
  loading$ = computed(() => !(this.routerEvents$() instanceof NavigationEnd));
  loaded$ = computed(() => this.dictionaryLoaded$());

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));

  routerEffect$ = effect(
    () => {
      const event = this.routerEvents$();
      const state = this.routeState$();

      if (!isPlatformBrowser(this.platformId)) {
        return;
      }
      if (
        event instanceof NavigationStart &&
        event.navigationTrigger !== 'imperative' &&
        !Object.keys(state?.state?.queryParams).some(a => this.preventScrollKeys.includes(a))
      ) {
        window.scrollTo(0, 0);
      }
      if (event instanceof NavigationEnd && this.cookiePrefs$()?.analytical) {
        if ((<any>window).gtag) {
          (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
        }
      }
      if (event instanceof NavigationEnd) {
        this.document.activeElement.blur();
        this.store.dispatch(themeActions.SetTheme({ theme: state?.state.data?.dark ? 'light' : 'dark' }));

        if (!state.state.params.projectId && !state.state.params.pageId) {
          this.store.dispatch(navActions.SetTopbarConfig({ topbar: state?.state.data?.topbar || null }));
        }
      }
      if (isPlatformBrowser(this.platformId)) {
        this.document.querySelector('body').classList.add('set--in');
        this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      }
    },
    { allowSignalWrites: true }
  );

  domainName = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  socialItems = [
    {
      title: 'Instagram',
      url: 'https://www.instagram.com/kcap.eu/?hl=nl',
    },
    {
      title: 'LinkedIn',
      url: 'https://www.linkedin.com/company/kcap-architects&planners/',
    },
  ];
  loadingTimeout: any;
  hasQueryParams = false;

  private previousPath = '';

  constructor(private cd: ChangeDetectorRef) {
    // this.router.events.subscribe(event => {
    //   if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
    //     (<any>window).gtag('config', 'UA-XXXXXXXXX-X', { page_path: event.urlAfterRedirects, anonymize_ip: true });
    //   }
    // });
    // this.router.events.subscribe(event => {
    //   if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
    //     // accessible tab order, should reset with route change
    //     document.activeElement.blur();
    //   }
    // });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

      window.addEventListener('resize', () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      });
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  private updateRoot(menuActive) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }
}
